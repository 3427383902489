<template>
  <b-container
    class="mt-2"
    fluid
  >
    <!-- Filters -->
    <b-row>

      <b-col
        cols="12"
        md="3"
        class="px-1"
      >
        <b-form-group
          label="Trip Type"
          label-for="filterPendingTripType"
          label-class="font-weight-bolder"
        >
          <b-form-select
            id="filterPendingTripType"
            v-model="tablePending.filter.trip_type"
            :options="tablePending.options.tripTypes"
            :disabled="tablePending.busy"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                -- select trip type here --
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="5"
        class="px-1"
      >
        <b-form-group
          label="Vehicle"
          label-for="filterVehicle"
          label-class="font-weight-bolder"
          class="mb-2"
        >
          <v-select
            v-model="tablePending.selected.vehicle"
            placeholder="search vehicle here"
            label="vehicle_name"
            class="vs-white"
            clearable
            append-to-body
            input-id="filterVehicle"
            :options="(tablePending.options.vehicles)"
            :loading="(tablePending.fetching.vehicles)"
            :disabled="(tablePending.busy || tablePending.fetching.vehicles)"
            :calculate-position="calculateDropPosition"
          >
            <template #option="{ vehicle_plate, vehicle_name }">
              <div class="d-flex flex-column flex-md-row py-1">
                <div class="flex-grow-1">
                  {{ vehicle_name }}
                </div>
                <strong class="min-w-50px">{{ vehicle_plate }}</strong>
              </div>
            </template>
            <template #no-options="">
              no available vehicle
            </template>
          </v-select>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="4"
        class="px-1"
      >
        <b-form-group
          label="Delivery Date"
          label-for="filterPendingDeliveryDate"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filterPendingDeliveryDate"
            v-model="tablePending.filter.date"
            debounce="1000"
            type="date"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        class="px-1"
      >
        <b-form-group
          label="Search"
          label-for="filterPendingSearch"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filterPendingSearch"
            v-model="tablePending.filter.search"
            placeholder="search here"
            autocomplete="off"
            debounce="1000"
            type="text"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        class="px-1"
      >
        <div
          v-if="tablePending.totalRows > 0"
          class="d-flex order-md-1 order-2 pb-2"
          style="column-gap: 5px; row-gap: 5px;"
        >
          <b-button
            v-if="selected.details.length <= 0"
            size="sm"
            variant="outline-success"
            class="text-nowrap min-w-100px"
            @click.prevent="$event => selectPending(true)"
          >
            Select All
          </b-button>
          <b-button
            v-else-if="selected.details.length > 0"
            size="sm"
            variant="outline-success"
            class="text-nowrap min-w-100px"
            @click.prevent="$event => selectPending(false)"
          >
            Unselect All
          </b-button>
          <b-button
            v-if="selected.details.length > 0"
            size="sm"
            class="text-nowrap"
            @click.prevent="$event => showSelectedPending()"
          >
            Dispatch Deliveries
          </b-button>
        </div>
      </b-col>
    </b-row>

    <!-- Table -->
    <b-row
      v-if="Number(tabIndex) === 0"
      class="mt-1"
    >
      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tablePending"
          small
          hover
          striped
          bordered
          responsive
          show-empty
          :items="tableProvider"
          :busy="tablePending.busy"
          :filter="tablePending.filter"
          :fields="tablePending.fields"
          :stacked="tablePending.stacked"
          :sort-by.sync="tablePending.sortBy"
          :sort-desc.sync="tablePending.sortDesc"
          :sort-direction="tablePending.sortDirection"
          :filter-included-fields="tablePending.filterOn"
          :current-page="tablePending.currentPage"
          :per-page="tablePending.perPage"
        >
          <template #cell(index)="{ index }">
            {{ tableRowNumber(tablePending, index) }}
          </template>

          <template #head(selected)>
            <b-form-checkbox
              v-model="selected.allSelected"
              :indeterminate="selected.indeterminate"
              :disabled="(tablePending.busy || Number(tablePending.totalRows) <= 0)"
              size="lg"
              @change="selectPending"
            />
          </template>

          <template #cell(selected)="{ item }">
            <b-form-checkbox
              v-model="selected.details"
              :value="item.id"
              :disabled="tablePending.busy"
              size="lg"
            />
          </template>

          <template #cell(action)="{ item }">
            <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
              <b-button
                size="sm"
                variant="primary"
                class="mr-0 mr-md-1 mb-1 mb-md-0"
                :disabled="tablePending.busy || selected.details.length > 0"
                @click.prevent="show(item)"
              >
                Process
              </b-button>
            </div>
          </template>

          <template #cell(approvable.purchase_number)="{ value }">
            <b
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #cell(approvable.transfer_number)="{ value }">
            <b
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #cell()="{ value }">
            <div
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="mt-1 mb-2 px-1"
      >
        <b-select
          v-model="tablePending.perPage"
          :options="tablePending.pageOptions"
          :disabled="tablePending.busy"
          class="w-100 w-md-25"
          size="sm"
        />
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tablePending.currentPage"
          :total-rows="tablePending.totalRows"
          :per-page="tablePending.perPage"
          :disabled="tablePending.busy"
          aria-controls="table"
          prev-text="Prev"
          next-text="Next"
          first-number
          last-number
          pills
        />
      </b-col>

    </b-row>

    <b-modal
      id="modalDispatchingMultiple"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      modal-class="fullscreen"
    >
      <b-container
        fluid
        style="overflow-y: auto !important;"
      >
        <b-row class="mx-0 mt-1 mb-0">

          <b-col
            cols="12"
            class="px-1 min-h-25px"
          >
            <div
              v-if="selected.details.length > 0 && selected.details.length >= 2"
              class="d-flex order-md-1 order-2"
              style="column-gap: 5px; row-gap: 5px;"
            >
              <b-button
                v-if="tablePending.selected.details.length <= 0"
                size="sm"
                variant="outline-success"
                class="text-nowrap min-w-100px"
                @click.prevent="$event => selectDisapatchings(true)"
              >
                Select All
              </b-button>
              <b-button
                v-else-if="tablePending.selected.details.length > 0"
                size="sm"
                variant="outline-success"
                class="text-nowrap min-w-100px"
                @click.prevent="$event => selectDisapatchings(false)"
              >
                Unselect All
              </b-button>
              <b-button
                v-if="tablePending.selected.details.length > 0"
                size="sm"
                variant="outline-danger"
                class="text-nowrap"
                @click.prevent="$event => removeDisapatchings()"
              >
                Remove Selected
              </b-button>
            </div>
          </b-col>

          <b-col
            cols="12"
            class="mt-2 px-0 px-md-1"
          >
            <b-table-simple
              small
              responsive
              sticky-header="calc(100vh - 9rem)"
            >
              <b-thead>
                <b-tr>
                  <b-th class="w-50px text-center">
                    <b-form-checkbox
                      v-model="tablePending.selected.allSelected"
                      :indeterminate="tablePending.selected.indeterminate"
                      :disabled="selected.details.length <= 1"
                      size="lg"
                      @change="selectDisapatchings"
                    />
                  </b-th>
                  <b-th class="w-150px border-left" />
                  <b-th class="w-250px" />
                  <b-th class="w-250px text-center border-left bg-light-dark">
                    Category
                  </b-th>
                  <b-th class="w-450px border-left bg-light-dark">
                    Product
                  </b-th>
                  <b-th class="w-100px text-center border-left bg-light-dark">
                    Quantity
                  </b-th>
                  <b-th class="w-50px text-center" />
                </b-tr>
              </b-thead>
              <b-tbody
                v-for="(request, requestKey) in stockRequest.details"
                :key="`details-${requestKey}`"
              >
                <b-tr
                  v-if="requestKey >= 1"
                >
                  <b-td
                    colspan="7"
                    variant="secondary"
                    class="text-right"
                  />
                </b-tr>

                <b-tr
                  v-for="(product, detailKey) in request.details"
                  :key="`product-${requestKey}-${detailKey}`"
                >
                  <b-td
                    v-if="(request.details.findIndex(detail => detail.group_id === product.group_id) === detailKey)"
                    :rowspan="(request.details.filter(detail => detail.group_id === product.group_id).length + 3)"
                    style="vertical-align: top !important; text-align: center;"
                    class="border-right"
                  >
                    <div class="text-md-nowrap d-flex justify-content-center align-items-center min-h-35px">
                      <b-form-checkbox
                        v-model="tablePending.selected.details"
                        :disabled="selected.details.length <= 1"
                        :value="request.id"
                        size="lg"
                      />
                    </div>
                  </b-td>

                  <template v-if="detailKey === 0">
                    <b-td>
                      PO Number
                    </b-td>
                    <b-td
                      class="border-left"
                    >
                      <strong>{{ request.purchase_number }}</strong>
                    </b-td>
                  </template>

                  <template v-else-if="detailKey === 1">
                    <b-td>
                      Transfer Number
                    </b-td>
                    <b-td
                      class="border-left"
                    >
                      <strong>{{ request.transfer_number }}</strong>
                    </b-td>
                  </template>

                  <template v-else-if="detailKey === 2">
                    <b-td>
                      Deliver To
                    </b-td>
                    <b-td
                      colspan="2"
                      class="border-left"
                    >
                      {{ request.destination_name }}
                    </b-td>
                  </template>

                  <template v-else-if="detailKey === 3">
                    <b-td>
                      Delivery From
                    </b-td>
                    <b-td
                      colspan="2"
                      class="border-left"
                    >
                      {{ request.origin_name }}
                    </b-td>
                  </template>

                  <template v-else-if="detailKey === 4">
                    <b-td>
                      Direct to Customer
                    </b-td>
                    <b-td
                      colspan="2"
                      class="border-left"
                    >
                      {{ request.customer }}
                    </b-td>
                  </template>

                  <template v-else-if="detailKey === 5">
                    <b-td>
                      Mobile
                    </b-td>
                    <b-td
                      colspan="2"
                      class="border-left"
                    >
                      {{ request.mobile_number }}
                    </b-td>
                  </template>

                  <template v-else-if="detailKey === 6">
                    <b-td>
                      Address
                    </b-td>
                    <b-td
                      colspan="2"
                      class="border-left"
                    >
                      {{ request.address }}
                    </b-td>
                  </template>

                  <template v-else-if="detailKey === 7">
                    <b-td>
                      Requester Remarks
                    </b-td>
                    <b-td
                      colspan="2"
                      :rowspan="request.details.length - 6"
                      class="border-left"
                      style="vertical-align: top !important;"
                    >
                      {{ request.requester_remarks || 'no remarks' }}
                    </b-td>
                  </template>

                  <template v-else-if="detailKey >= 8">
                    <b-td>
                      &nbsp;
                    </b-td>
                  </template>

                  <b-td
                    v-if="(request.details.findIndex(detail => String(detail.group_id) === String(product.group_id)) === detailKey)"
                    rowspan="2"
                    style="vertical-align: middle; text-align: center;"
                    class="border-left font-weight-bolder text-success"
                  >
                    {{ product.category_name }}
                  </b-td>

                  <b-td class="border-left">
                    {{ product.product_name }}
                  </b-td>

                  <b-td class="text-center border-left">
                    {{ product.quantity }}
                  </b-td>

                  <b-td
                    v-if="(request.details.findIndex(detail => detail.group_id === product.group_id) === detailKey)"
                    :rowspan="(request.details.filter(detail => detail.group_id === product.group_id).length + 3)"
                    style="vertical-align: top !important; text-align: center;"
                    class="border-left"
                  >
                    <div class="text-md-nowrap d-flex justify-content-center align-items-center min-h-35px">
                      <b-button
                        variant="outline-danger"
                        class="border-0"
                        :disabled="selected.details.length <= 1"
                        @click.prevent="removeDisapatching(request, requestKey)"
                      >
                        <b-icon
                          icon="trash"
                          font-scale="1"
                        />
                      </b-button>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr :key="`footer-${requestKey}`">
                  <b-td />
                  <b-td
                    class="text-right border-left"
                  >
                    Total Products
                  </b-td>
                  <b-td
                    class="text-center border-left border-right"
                  >
                    <b>
                      {{
                        request.details.map(({ quantity }) => quantity).reduce(
                          (total, amount) => Number(total) + Number(amount), 0
                        )
                      }}
                    </b>
                  </b-td>
                </b-tr>

                <b-tr :key="`footer-2-${requestKey}`">
                  <b-td
                    class="border-left"
                    style="vertical-align: middle !important; text-align: center;"
                  >
                    {{ request.trip_type }} Schedule
                  </b-td>
                  <b-td
                    colspan="2"
                    class="px-1 border-left border-right"
                  >
                    <b-row
                      class="px-2"
                    >
                      <b-col
                        cols="12"
                        md="3"
                        class="px-1 px-md-2"
                      >
                        <b-form-group
                          label="Date"
                          label-for="fvDeliveryDate"
                          class="mb-1"
                        >
                          <strong
                            v-text="request.delivery_date"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="12"
                        md="3"
                        class="px-1 px-md-2"
                      >
                        <b-form-group
                          label="Plate Number"
                          label-for="fvVehiclePlate"
                          class="mb-1"
                        >
                          <strong
                            v-text="request.vehicle_plate"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="12"
                        md="6"
                        class="px-1 px-md-2"
                      >
                        <b-form-group
                          label="Vehicle"
                          label-for="fvVehicleName"
                          class="mb-1"
                        >
                          <strong
                            v-text="request.vehicle_name"
                          />
                        </b-form-group>
                      </b-col>

                    </b-row>
                  </b-td>

                  <b-td
                    colspan="2"
                    class="px-1 border-left border-right"
                  >
                    <b-row
                      class="px-2"
                    >
                      <b-col
                        cols="12"
                        md="4"
                        class="px-1 px-md-2"
                      >
                        <b-form-group
                          label="Mobile Number"
                          label-for="fvDriverNumber"
                          class="mb-1"
                        >
                          <strong
                            v-text="request.driver_number"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="12"
                        md="4"
                        class="px-1 px-md-2"
                      >
                        <b-form-group
                          label="Driver Name"
                          label-for="fvDriverName"
                          class="mb-1"
                        >
                          <strong
                            v-text="request.driver_name"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="12"
                        md="4"
                        class="px-1 px-md-2"
                      >
                        <b-form-group
                          label="Helper"
                          label-for="fvHelper"
                          class="mb-1"
                        >
                          <strong
                            v-text="request.helper"
                          />
                        </b-form-group>
                      </b-col>

                    </b-row>
                  </b-td>
                </b-tr>

                <b-tr
                  :key="`footer-3-${requestKey}`"
                  class="border-bottom"
                >
                  <b-td
                    class="border-left"
                    style="vertical-align: middle !important; text-align: center;"
                  >
                    Remarks
                  </b-td>
                  <b-td
                    colspan="4"
                    class="px-1 border-left border-right"
                    style="background-color: #eee;"
                  >
                    <b-textarea
                      id="fvRemarks"
                      v-model="request.remarks"
                      class="p-1 rounded-1"
                      rows="4"
                      max-rows="4"
                      maxlength="50"
                      placeholder="enter remarks here ( optional )"
                    />
                  </b-td>
                </b-tr>

              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer="{ cancel }">
        <div class="flex-grow-1 d-flex justify-content-between mb-0 pb-0">
          <div>
            <b-button
              variant="secondary"
              @click.prevent="cancel()"
            >
              Select More
            </b-button>
          </div>
          <div>
            <b-button
              variant="primary"
              class="mr-2"
              @click.prevent="validateDisapatching"
            >
              Dispatch
            </b-button>
            <b-button
              variant="outline-dark"
              @click.prevent="cancel()"
            >
              Close <span class="d-none d-md-inline-block">Window</span>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import { SLODispatching, SSharedList } from '@/services'

export default {

  name: 'LogisticsOfficerDispatchingPending',

  mixins: [
    MISC,
    FORMATTER
  ],

  props: {
    tabIndex: {
      type: Number,
      default: () => 0
    }
  },

  data () {
    return {
      selected: {
        allSelected: false,
        indeterminate: false,
        details: []
      },
      stockRequest: {
        id: 0,
        details: []
      },
      tablePending: {
        busy: false,
        fetching: {
          vehicles: false
        },
        options: {
          tripTypes: [
            { value: 'all', text: 'All' },
            { value: 'Delivery', text: 'Delivery' },
            { value: 'Pick-Up', text: 'Pick-Up' },
            { value: 'Inter Branch', text: 'Inter Branch' }
          ],
          vehicles: []
        },
        selected: {
          allSelected: false,
          indeterminate: false,
          details: [],
          vehicle: null
        },
        filter: {
          search: null,
          date: null,
          vehicle: null,
          trip_type: 'all'
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        rows: [],
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 1, key: 'selected', class: 'text-center' },
          { mobile: 15, key: 'action', class: 'text-center' },
          { mobile: 2, key: 'approvable.created_at', label: 'Requested At', class: 'min-w-175px', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 3, key: 'approvable.purchase_number', label: 'PO Number', class: 'text-center min-w-150px', sortable: true },
          { mobile: 4, key: 'approvable.transfer_number', label: 'Transfer Number', class: 'text-center min-w-150px', sortable: true },
          { mobile: 5, key: 'approvable.schedule_details.trip_type', label: 'Trip Type', class: 'min-w-150px' },
          { mobile: 6, key: 'approvable.schedule_details.date', label: 'Delivery Date', class: 'min-w-175px', formatter: this.dateShortFormatter, sortable: true },
          { mobile: 7, key: 'approvable.schedule_details.vehicle_plate', label: 'Vehicle Plate', class: 'min-w-150px' },
          { mobile: 8, key: 'approvable.schedule_details.driver_name', label: 'Driver', class: 'min-w-200px' },
          { mobile: 9, key: 'approvable.destination_name', label: 'Deliver To', class: 'min-w-200px' },
          { mobile: 10, key: 'approvable.origin_name', label: 'Delivery From', class: 'min-w-200px' },
          { mobile: 11, key: 'approvable.group_name', label: 'Group', class: 'min-w-200px' },
          { mobile: 12, key: 'approvable.transfer_details.length', label: 'Products', class: 'text-center' },
          { mobile: 13, key: 'approvable.delivery_details.customer', label: 'Customer', class: 'min-w-200px' },
          { mobile: 14, key: 'approvable.customer_direct', label: 'D2C', class: 'text-center', formatter: this.yesOrNo }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  watch: {

    'selected.details' (newValues) {
      if (newValues.length === 0) {
        this.selected.indeterminate = false
        this.selected.allSelected = false
      } else if (newValues.length === this.tablePending.rows.length) {
        this.selected.indeterminate = false
        this.selected.allSelected = true
      } else {
        this.selected.indeterminate = true
        this.selected.allSelected = false
      }
    },

    'tablePending.selected.details' (newValues) {
      if (newValues.length === 0) {
        this.tablePending.selected.indeterminate = false
        this.tablePending.selected.allSelected = false
      } else if (newValues.length === this.stockRequest.details.length) {
        this.tablePending.selected.indeterminate = false
        this.tablePending.selected.allSelected = true
      } else {
        this.tablePending.selected.indeterminate = true
        this.tablePending.selected.allSelected = false
      }
    },

    'tablePending.selected.vehicle' (vehicle) {
      this.tablePending.filter.vehicle = vehicle?.id || null
    }
  },

  mounted () {
    this.getFilterVehicles()
  },

  methods: {

    async getFilterVehicles () {
      this.tablePending.fetching.vehicles = true
      return await SSharedList.getVehicles().then(({ data }) => {
        this.tablePending.options.vehicles = data
      }).catch(() => {
        this.tablePending.options.vehicles = []
      }).finally(() => {
        this.tablePending.fetching.vehicles = false
      })
    },

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tablePending.busy = true
      return await SLODispatching.get({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search,
        filter_delivery_date: filter.date,
        filter_vehicle: filter.vehicle,
        filter_trip_type: filter.trip_type
      }).then(({ data }) => {
        this.tablePending.totalRows = data.total_rows
        this.tablePending.rows = data.items
        return data.items
      }).catch(() => {
        this.tablePending.totalRows = 0
        return []
      }).finally(() => {
        this.tablePending.busy = false
      })
    },

    tableRefresh () {
      this.$refs.tablePending.refresh()
    },

    show (stockRequest) {
      this.$emit('show', stockRequest, true)
    },

    selectPending (selectAll) {
      this.selected.details = selectAll ? this.tablePending.rows.map(
        ({ id }) => Number(id)
      ) : []
    },

    showSelectedPending () {
      this.tablePending.selected.details = []

      const newDetails = this.tablePending.rows.filter(
        ({ id }) => this.selected.details.includes(Number(id))
      ).map(
        ({ approvable, id }) => {
          const additionalRows = Number(approvable.transfer_details.length) >= 8 ? 0 : (
            8 - Number(approvable.transfer_details.length)
          )
          const additionalDetails = Array.from({ length: additionalRows }).map(
            () => ({
              id: null,
              transfer_id: null,
              group_id: approvable.group_id,
              group_code: approvable.group_code,
              group_name: approvable.group_name,
              category_id: null,
              category_code: null,
              category_name: null,
              product_id: null,
              product_code: null,
              product_name: null,
              quantity: null,
              approved_quantity: null
            })
          )
          const { delivery_details, schedule_details } = approvable
          return ({
            id: id,
            transfer_number: approvable.transfer_number,
            purchase_number: approvable.purchase_number,
            destination_name: approvable.destination_name,
            origin_name: approvable.origin_name,
            customer_direct: approvable.customer_direct,
            customer: delivery_details.customer,
            address: delivery_details.address,
            mobile_number: delivery_details.mobile_number,
            trip_type: schedule_details.trip_type,
            delivery_date: schedule_details.date,
            vehicle_plate: schedule_details.vehicle_plate,
            vehicle_name: schedule_details.vehicle_name,
            driver_number: schedule_details.mobile_number,
            driver_name: schedule_details.driver_name,
            helper: schedule_details.helper,
            details: approvable.transfer_details.map(
              detail => ({
                id: detail.id,
                transfer_id: detail.transfer_id,
                group_id: approvable.group_id,
                group_code: approvable.group_code,
                group_name: approvable.group_name,
                category_id: detail.category_id,
                category_code: detail.category_code,
                category_name: detail.category_name,
                product_id: detail.product_id,
                product_code: detail.product_code,
                product_name: detail.product_name,
                quantity: detail.posting_quantity
              })
            ).concat(additionalDetails),
            requester_remarks: approvable.remarks,
            remarks: this.stockRequest.details.find(
              finder => Number(finder.id) === Number(id)
            )?.remarks || null
          })
        }
      )

      this.stockRequest.details = newDetails
      this.$bvModal.show('modalDispatchingMultiple')
    },

    selectDisapatchings (selectAll) {
      this.tablePending.selected.details = selectAll ? this.stockRequest.details.map(
        ({ id }) => Number(id)
      ) : []
    },

    removeDisapatching (dispatching, _key) {
      const newDetails = this.stockRequest.details.filter(
        ({ id }) => Number(id) !== Number(dispatching.id)
      )

      if (newDetails.length <= 0) {
        return this.swalInvalid('<h6>Disapatching must have atleast 1 request</h6>', 'Removal Canceled')
      }

      this.selected.details = this.selected.details.filter(
        id => Number(id) !== Number(dispatching.id)
      )

      this.stockRequest.details = newDetails
    },

    removeDisapatchings () {
      const newDetails = this.stockRequest.details.filter(
        ({ id }) => !this.tablePending.selected.details.includes(
          Number(id)
        )
      )

      if (newDetails.length <= 0) {
        return this.swalInvalid('<h6>Disapatching must have atleast 1 request</h6>', 'Removal Canceled')
      }

      this.selected.details = newDetails.map(
        ({ id }) => Number(id)
      )
      this.stockRequest.details = newDetails
      this.tablePending.selected.details = []
    },

    validateDisapatching () {
      this.swalConfirm({
        html: 'Dispatch Scheduled Deliveries?',
        preConfirm: async () => {
          return this.update({
            id: 0,
            selected: this.stockRequest.details.map(
              ({ id, remarks }) => ({ id, remarks })
            ),
            status: 'Dispatched'
          }).then(({ message }) => {
            this.$bvModal.hide('modalDispatchingMultiple')
            this.swalSuccess(message).then(() => {
              this.selected.details = []
              this.stockRequest.details = []
              this.tablePending.selected.details = []
              this.tableRefresh()
            })
          })
        },
        confirmButtonText: 'Dispatch'
      })
    },

    async update (payload) {
      return new Promise((resolve, reject) => {
        SLODispatching.put(payload).then(
          ({ data: { message } }) => {
            this.$bvModal.hide('modalDispatching')
            this.swalSuccess(message).then(() => {
              this.tableRefresh()
            })
            resolve({ message })
          }
        ).catch(
          ({ message }) => reject(message)
        )
      })
    }

  }
}
</script>
